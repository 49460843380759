input::placeholder{
    opacity: 0;
}

input:focus::placeholder{
    opacity: 1;
}

input:hover {
    border: 1px solid transparent;
    border-color: #e6e6e6;
}

input {
    border: 1px solid transparent;
    border-radius: 8px;
    height: 28px;
    text-align: center;
    width: auto;
    resize: none;
    background: transparent;
}

select {
    background: transparent;
}

label {
    padding-right: 8px;
}

.btn-header {
    margin-left: 8px;
}

.container {
    max-width: 95%;
    margin-left: 2%;
    margin-right: 2%;
}

.create-task-textbox {
    border-color: #e6e6e6;
    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
    width: 242px;
    align-self: center;
    margin-left: 61px;
}


/* Contains all group cards */
.group-tasks {
    margin-bottom: 16px;
    margin-left: -12px;
    margin-right: -12px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

.ungrouped-tasks {
    margin-bottom: 16px;
    margin-left: -12px;
    margin-right: -12px;
}

.empty-group-container {
    text-align: center;
    width: 100%;
}


/* Card for a group */
.group-card {
    margin-left: 0px;
    margin-right: 6px;
    margin-bottom: 16px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 440px;
    min-height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    flex-grow: 0;
}

.card-header:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.card-header {
    width: inherit;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-color: rgba(0, 0, 0, 0.25);
    border-top-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-bottom-width: 0px;
    border-style: solid;
    color: rgb(33, 37, 41);
    border-bottom: 0px;
    overflow-wrap: break-word;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
}

.card-body {
    color: rgb(33, 37, 41);
    display: block;
    overflow-wrap: break-word;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 4px;
    padding-right: 4px;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border-color:  rgba(0, 0, 0, 0.25);
    border-bottom-width: 1px;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 0px;
    border-style: solid;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 4px 0px;
}

.task-table {
    table-layout: unset;
}

.task-row {
    display: flex;
    flex-direction: row;
    padding: 0px;
    border: 0px;
    margin: 0px;
    border-radius: 6px;
    height: max-content;
    width: fit-content;
    vertical-align: middle;
}

.task-drag-element {
    padding-top: 4px;
    padding-left: 6px;
    padding-right: 6px;
}

.task-checkbox {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
}

.task-textbox {
    padding: 4px;
    border: 0px;
    margin: 0px;
    width: 242px;
    text-align: center;
}

.task-completion-date {
    padding: 4px;
    border: 0px;
    margin: 0px;
    text-align: center;
}

.task-group-selector {
    padding: 6px;
}

.task-delete-box {
    padding: 0px;
    border: 0px;
    margin: 0px;
    align-self: right;
}

.group-settings-row {
    margin-top: 12px;
    margin-bottom: -8px;
}

.group-settings-button {
    margin-left: 8px;
    height: 38px;
}

.group-archive {
    margin-left: 175%;
}

.group-change-color {
    text-align: right;
    margin-top: 6px;
}

.task-delete-button {
    border: 1px solid transparent;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
}

.calendar-selection {
    height: 38px;
    margin: 20px;
}

.day-container {
    margin: auto;
    width: 645px;
    padding: 20px;
    border-color: rgba(0, 0, 0, 0.125);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
}

.day-header {
    text-align: center;
    padding: 5px;
    font-weight:bold
}

.sign-in-view {
    margin: auto;
    width: fit-content;
    text-align: center;
}

.sign-in-button {
    margin: auto;
    width: fit-content;
}

.view-selection  {
    margin-left: 2%;
    margin-right: auto;
}

.view-selector {
    margin: 6px;
    display: inline-block;
}

.view-selector:hover {
    color: gray;
}

.date-jump-input-container {
    margin: auto;
    width: fit-content;
    padding: 6px;
}


.hidden {
    visibility: hidden;
    height: 0px;
    width: 0px;
}

.popup-container {
    z-index: 1000;
    width: 50%;
    height: 75%;
    left: 25%;
    top: 10%;
    position: fixed;
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
}

.settings-title {
    text-align: center;
    margin: auto;
}

.close-button {
    align-self: right;
}

.settings-header-container {
    display: flex;
    align-content: center;
    margin: 8px;
}

.settings-items-table {
    margin: auto;
}

.settings-items-table td {
    padding-inline: 15px;
    padding-top: 8px;
}

.dropdown-content {
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    padding: 8px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
}

#delete-account {
    position: absolute;
    bottom: 5%;
    right: 5%;
}

.undo-delete-div {
    position: fixed;
    bottom: 2%;
    left: 2%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: white;
    border: 2px solid rgba(0, 0, 0, 0.125);
    border-radius: 8px;
    padding: 6px;
}

.undo-task-delete-div {
    display: block;
}

.undo-delete-button {
    margin: 6px;
}

.add-group-button {
    position: fixed;
    bottom: 2%;
    right: 2%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: white;
}
